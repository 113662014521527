<template>
  <b-card
    class="card-company-table"
  >
    <b-tabs>
      <b-tab :title="$t('tests.last_activity')">
        <!-- <b-card-header>
          <b-card-title>{{ $t('tests.last_activity') }}</b-card-title>
        </b-card-header> -->
        <b-table
          :items="GET_STUDENT_COURSE.filter(e => e.is_individual && !e.is_outdated)"
          responsive
          :fields="fields"
          class="mb-0"
          show-empty
          :empty-text="$t('general.no_records_to_show')"
          hover
          @row-clicked="(e) => goToCourse(e)"
        >
          <template #cell(index)="{ index}">
            {{ index + 1 }}
          </template>
          <template #head()="{ label }">
            {{ $t(label) }}
          </template>
          <template #cell(title)="data">
            <div class="font-weight-bolder">
              {{ data.item.course.title }}
            </div>
            <small
              v-if="data.item.course.subject[$i18n.locale]"
              class="text-muted"
            >
              {{ data.item.course.subject[$i18n.locale] }}
            </small>
          </template>
          <template #cell(access)="{ item }">
            {{ $_dateTimeFormatter(item.access_start_date).split(',')[0] }} - {{ $_dateTimeFormatter(item.access_end_date).split(',')[0] }}
          </template>
          <template #cell(group)="{ value }">
            <b-badge :variant="value ? 'success': 'danger'">
              {{ value ? value: 'Индивидуал' }}
            </b-badge>
          </template>

          <template #cell(chapter_statistics)="{ item }">
            <b-progress
              v-if="GET_STUDENT_COURSE_STATS(item.course.id).chapter_statistics || GET_STUDENT_COURSE_STATS(item.course.id).chapter_statistics === 0"
              :value="GET_STUDENT_COURSE_STATS(item.course.id).chapter_statistics"
              max="100"
              striped
              class="progress-bar-primary min-width-100"
              variant="primary"
              animated
              show-value
            />
            <div v-else class="d-flex justify-content-center">
              <img src="@/assets/images/icons/loading.gif" alt="loading" style="max-width: 100%; width: 50%; height: 50px; object-fit: contain">
            </div>
          </template>
          <template
            #cell(teachers)="{ item }"
          >

            <b-avatar-group size="32px">
              <b-avatar
                v-for="teacher in item.teachers"
                :key="teacher.id"
                variant="info"
                :title="teacher.full_name"
                class="pull-up"
                :src="teacher.photo"
              />
            </b-avatar-group>
          </template>
        </b-table>
      </b-tab>
      <b-tab :title="$t('tests.outdated')">
        <!-- <b-card-header>
          <b-card-title>{{ $t('tests.last_activity') }}</b-card-title>
        </b-card-header> -->
        <b-table
          :items="GET_STUDENT_COURSE.filter(e => e.is_outdated)"
          responsive
          :fields="fields"
          class="mb-0"
          show-empty
          :empty-text="$t('general.no_records_to_show')"
          hover
          @row-clicked="(e) => goToCourse(e)"
        >
          <template #cell(index)="{ index}">
            {{ index + 1 }}
          </template>
          <template #head()="{ label }">
            {{ $t(label) }}
          </template>
          <template #cell(title)="data">
            <div class="font-weight-bolder">
              {{ data.item.course.title }}
            </div>
            <small
              v-if="data.item.course.subject[$i18n.locale]"
              class="text-muted"
            >
              {{ data.item.course.subject[$i18n.locale] }}
            </small>
          </template>
          <template #cell(access)="{ item }">
            {{ $_dateTimeFormatter(item.access_start_date).split(',')[0] }} - {{ $_dateTimeFormatter(item.access_end_date).split(',')[0] }}
          </template>
          <template #cell(group)="{ value }">
            <b-badge :variant="value ? 'success': 'danger'">
              {{ value ? value: 'Индивидуал' }}
            </b-badge>
          </template>
          <template
            #cell(teachers)="{ item }"
          >

            <b-avatar-group size="32px">
              <b-avatar
                v-for="teacher in item.teachers"
                :key="teacher.id"
                variant="info"
                :title="teacher.full_name"
                class="pull-up"
                :src="teacher.photo"
              />
            </b-avatar-group>
          </template>
        </b-table>
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import {
  BCard,
  BTable,
  BAvatarGroup,
  BAvatar,
  // BCardHeader,
  // BCardTitle,
  BBadge,
  BTab,
  BTabs,
  BProgress,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import formatter from '@/mixins/extra'

export default {
  components: {
    BCard,
    BTable,
    // BCardHeader,
    // BCardTitle,
    BAvatarGroup,
    BAvatar,
    BBadge,
    BTab,
    BTabs,
    BProgress,
  },
  mixins: [formatter],
  props: {
    profile: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      fields: [
        {
          label: '№',
          key: 'index',
        },
        {
          label: 'general.title',
          key: 'title',
        },
        {
          label: 'users.class',
          key: 'group',
        },
        {
          label: 'users.teachers',
          key: 'teachers',
        },
        {
          label: 'others.information',
          key: 'chapter_statistics',
        },
        {
          label: 'users.access',
          key: 'access',
        },
        {
          label: 'users.price',
          key: 'course.price',
        },
      ],
    }
  },
  computed: {
    ...mapGetters('contacts', ['GET_STUDENT_COURSE', 'GET_STUDENT_COURSE_STATS']),
  },
  mounted() {
    // this.FETCH_STUDENT_COURSE(this.profile.contact.id)
  },
  methods: {
    ...mapActions('contacts', ['FETCH_STUDENT_COURSE']),
    ...mapActions('courses', ['FETCH_ONE_COURSE']),
    calculateLessonProgress(lesson) {
      if (lesson.is_test) {
        return lesson.progress_lesson_history.total_score ? this.calculateTestProgress(lesson) : 0
      }
      return 100
    },
    goToCourse(rel) {
      this.FETCH_ONE_COURSE(rel.course.id).then(res => {
        if (res.is_type) {
          this.$router.push(`/course/form-types/${rel.course.id}/${rel.id}`)
        } else {
          this.$router.push(`/course/${rel.course.id}/${rel.id}`)
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
