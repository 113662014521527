<template>
  <b-card
    class="card-transaction cursor-pointer"
    no-body
    @click="$router.push({ name: 'settlements' })"
  >
    <b-card-header>
      <b-card-title>{{ $t('users.mutual_settlements') }}</b-card-title>

    </b-card-header>

    <b-card-body>
      <!-- User Stats -->
      <div class="d-flex align-items-center">
        <div class="d-flex align-items-center mr-2">
          <b-avatar
            variant="light-primary"
            rounded
            size="60px"
          >
            <feather-icon
              icon="CreditCardIcon"
              size="24"
            />
          </b-avatar>
          <div class="ml-1">
            <h5
              class="mb-0"
            >
              {{ userId.contact.balance }} SO'M
            </h5>
            <small>{{ $t('finance.current_balance') }}</small>
          </div>
        </div>
      </div>
      <hr class="mb-2">
      <div
        v-for="transaction in GET_USER_TRANSACTION"
        :key="transaction.mode"
        class="transaction-item"
      >
        <b-media no-body>
          <b-media-aside>
            <b-avatar
              rounded
              size="42"
              :variant="transaction.avatarVariant"
            >
              <feather-icon
                size="18"
                :icon="transaction.avatar"
              />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h6 class="transaction-title">
              {{ transaction.type === 'income' ? $t('finance.income') : $t('finance.outcome') }}
            </h6>
            <small>{{ transaction.types }}</small>
          </b-media-body>
        </b-media>
        <div
          class="font-weight-bolder"
          :class="transaction.textVariant"
        >
          {{ transaction.amount }} so'm
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BMediaBody, BMedia, BMediaAside, BAvatar,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
  },
  props: {
    userId: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      transactionData: [
        {
          mode: 'Wallet',
          types: 'Starbucks',
          avatar: 'PocketIcon',
          avatarVariant: 'light-primary',
          payment: '-$74',
          deduction: true,
        },
        {
          mode: 'Bank Transfer',
          types: 'Add Money',
          avatar: 'CheckIcon',
          avatarVariant: 'light-success',
          payment: '+$480',
          deduction: false,
        },
        {
          mode: 'Paypal',
          types: 'Add Money',
          avatar: 'DollarSignIcon',
          avatarVariant: 'light-danger',
          payment: '+$480',
          deduction: false,
        },
        {
          mode: 'Mastercard',
          types: 'Ordered Food',
          avatar: 'CreditCardIcon',
          avatarVariant: 'light-warning',
          payment: '-$23',
          deduction: true,
        },
        {
          mode: 'Transfer',
          types: 'Refund',
          avatar: 'TrendingUpIcon',
          avatarVariant: 'light-info',
          payment: '+$98',
          deduction: false,
        },
      ],
    }
  },
  computed: {
    ...mapGetters('finance', ['GET_USER_TRANSACTION']),
  },
  mounted() {
    this.FETCH_USER_TRANSACTION()
    // .then(() => {
    //   this.transactionData = this.GET_USER_TRANSACTION
    // })
  },
  methods: {
    ...mapActions('finance', ['FETCH_USER_TRANSACTION']),
  },
}
</script>
