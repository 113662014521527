<template>
  <b-table
    id="course-list-of-teacher"
    striped
    :fields="fields"
    show-empty
    responsive
    :empty-text="$t('general.no_data_to_view')"
    :items="courses"
    @row-clicked="(data) => $router.push(`/users/${data.get_user}/info`)"
  >
    <template #head()="{ label }">
      {{ $t(label) }}
    </template>
    <template #cell(phones)="{ value }">
      <div
        v-for="item in value"
        :key="item"
      >
        <b-badge
          class="mr-1"
          variant="primary"
        >
          +{{ item }}
        </b-badge>
      </div>
      <div
        v-if="!value.length"
      >
        -
      </div>
    </template>
    <template #cell(gender)="{ value }">
      {{ $t('users.'+ value) }}
    </template>
    <template #cell(full_name)="{ item }">
      <b-avatar
        size="md"
        :src="item.photo"
        class="mr-2"
      />
      <span>{{ item.full_name }}</span>
    </template>
  </b-table>
</template>

<script>
import { BTable, BBadge, BAvatar } from 'bootstrap-vue'

export default {
  components: {
    BTable,
    BBadge,
    BAvatar,
  },
  props: {
    courses: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      fields: [
        {
          label: 'ID',
          key: 'id',
        },
        {
          label: 'users.full_name',
          key: 'full_name',
        },
        {
          label: 'users.gender',
          key: 'gender',
        },
        {
          key: 'phones',
          label: 'users.phone_number',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
    }
  },
}
</script>

<style>

</style>
