<template>
  <b-card
    no-body
    class="card-company-table"
  >
    <b-card-header>
      <b-card-title>{{ $t('courses.courses_by_class') }}</b-card-title>
    </b-card-header>
    <b-table
      :items="GET_STUDENT_COURSE.filter(e => !e.is_individual && !e.is_outdated)"
      responsive
      :fields="fields"
      class="mb-0"
      show-empty
      :empty-text="$t('general.no_records_to_show')"
      hover
      @row-clicked="(e) => $router.push(`/course/${e.course.id}/${e.id}`)"
    >
      <template #cell(index)="{ index }">
        {{ index + 1 }}
      </template>
      <template #head()="{ label }">
        {{ $t(label) }}
      </template>
      <template #cell(title)="data">
        <div class="font-weight-bolder">
          {{ data.item.course.title }}
        </div>
        <small
          v-if="data.item.course.subject[$i18n.locale]"
          class="text-muted"
        >
          {{ data.item.course.subject[$i18n.locale] }}
        </small>
      </template>
      <template #cell(access)="{ item }">
        {{ $_dateTimeFormatter(item.access_start_date).split(',')[0] }} - {{ $_dateTimeFormatter(item.access_end_date).split(',')[0] }}
      </template>
      <template #cell(group)="{ value }">
        <b-badge :variant="value ? 'success': 'danger'">
          {{ value ? value: 'Индивидуал' }}
        </b-badge>
      </template>
      <template
        #cell(teachers)="{ item }"
      >

        <b-avatar-group size="32px">
          <b-avatar
            v-for="teacher in item.teachers"
            :key="teacher.id"
            variant="info"
            :title="teacher.full_name"
            class="pull-up"
            :src="teacher.photo"
          />
        </b-avatar-group>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import {
  BCard,
  BTable,
  BAvatarGroup,
  BAvatar,
  BCardHeader,
  BCardTitle,
  BBadge,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import formatter from '@/mixins/extra'

export default {
  components: {
    BCard,
    BTable,
    BCardHeader,
    BCardTitle,
    BAvatarGroup,
    BAvatar,
    BBadge,
  },
  mixins: [formatter],
  props: {
    profile: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      fields: [
        {
          label: '№',
          key: 'index',
        },
        {
          label: 'general.title',
          key: 'title',
        },
        {
          label: 'users.class',
          key: 'group',
        },
        {
          label: 'users.teachers',
          key: 'teachers',
        },
        {
          label: 'users.access',
          key: 'access',
        },
        {
          label: 'users.price',
          key: 'course.price',
        },
      ],
    }
  },
  computed: {
    ...mapGetters('contacts', ['GET_STUDENT_COURSE']),
  },
  mounted() {
    // this.FETCH_STUDENT_COURSE(this.profile.contact.id)
  },
  methods: {
    ...mapActions('contacts', ['FETCH_STUDENT_COURSE']),
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
