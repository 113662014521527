<template>
  <div>
    <!-- Studentlar uchun -->
    <b-row>
      <b-col
        xl="8"
        md="6"
        sm="12"
      >
        <statistics
          v-if="$can('manage', 'admin') || $can('manage', 'super_admin')"
          :data="statistic"
        />
        <user-profile-card :profile="userProfileData" />
        <ClassesTableCard
          v-if="$can('manage', 'student')"
          :profile="userProfileData"
          class="mb-2"
        />
        <CoursesIsNotInduvidual
          v-if="$can('manage', 'student')"
          :profile="userProfileData"
          class="mb-2"
        />
        <!-- <courses-table-card
          class="mb-2"
        /> -->
        <b-row v-if="$can('manage', 'teacher')">
          <b-col cols="6">
            <b-card>
              <b-tabs>
                <b-tab :title="$t('classes.students')">
                  <enrolled-students :header="false" />
                </b-tab>
                <b-tab :title="$t('courses.students_by_classes')">
                  <EnrolledStudentsClass :header="false" />
                </b-tab>
              </b-tabs>
            </b-card>
          </b-col>
          <b-col cols="6">
            <b-card>
              <enrolled-classes :user-data="userProfileData" />
            </b-card>
          </b-col>
        </b-row>
      </b-col>
      <b-col
        v-if="$can('manage', 'student')"
        xl="4"
        md="6"
        sm="12"
      >
        <template>
          <div
            v-for="(card, index) in GET_STUDENT_COURSE"
            :key="index"
          >
            <course-progress-card
              class="cursor-pointer"
              :data="card"
              :series="Math.round(GET_STUDENT_COURSE_STATS(card.course.id).chapter_statistics)"
            />
          </div>
        </template>
        <!-- <memory-limit
          :data="{ all: '10 GB', completed: '9 GB', inProgress: '1 GB', series: [ 90 ] }"
        /> -->
        <transaction-card
          v-if="userProfileData.contact.hide_transaction"
          :user-id="userProfileData"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BTabs, BTab, BCard,
} from 'bootstrap-vue'
import { mapGetters, mapActions } from 'vuex'
import transactionCard from './components/transactionsCard.vue'
// import coursesTableCard from './components/coursesTableCard.vue'
import userProfileCard from './components/userProfileCard.vue'
import courseProgressCard from './components/courseProgressCard.vue'
import ClassesTableCard from './components/CourseTableCard.vue'
import useJwt from '@/auth/jwt/useJwt'
// import MemoryLimit from './components/MemoryLimit.vue'
import Statistics from './components/Statistics.vue'
import EnrolledStudents from './Teacher/EnrolledStudents.vue'
import EnrolledStudentsClass from './Teacher/EnrolledStudentsClass.vue'
import CoursesIsNotInduvidual from './components/CoursesIsNotInduvidual.vue'
import EnrolledClasses from './Teacher/EnrolledClasses.vue'

// import HeadCards from '@/components/HeadCards.vue'

export default {
  components: {
    BRow,
    BCol,
    transactionCard,
    // coursesTableCard,
    userProfileCard,
    courseProgressCard,
    ClassesTableCard,
    // MemoryLimit,
    // HeadCards,
    Statistics,
    EnrolledStudents,
    EnrolledStudentsClass,
    CoursesIsNotInduvidual,
    BTabs,
    BTab,
    BCard,
    EnrolledClasses,
  },
  data() {
    return {
      courseProgress: [
        {
          name: 'Новый курс 1',
          subject: 'Математика',
          series: [80, 20],
        },
        {
          name: 'Новый курс 2',
          subject: 'Английский',
          series: [10, 90],
        },
      ],
      // statistic: [
      //   {
      //     icon: 'UsersIcon', color: 'light-primary', title: '100', subtitle: 'Всего пользователей', customClass: 'mb-2 mb-xl-0',
      //   },
      //   {
      //     icon: 'UserIcon', color: 'light-info', title: '20', subtitle: 'Сотрудников', customClass: 'mb-2 mb-xl-0',
      //   },
      //   {
      //     icon: 'UserIcon', color: 'light-danger', title: '10', subtitle: 'Учителей', customClass: 'mb-2 mb-sm-0',
      //   },
      //   {
      //     icon: 'UserIcon', color: 'light-success', title: '70', subtitle: 'Учеников', customClass: '',
      //   },
      // ],
    }
  },
  computed: {
    ...mapGetters('contacts', ['GET_STUDENT_COURSE', 'GET_STUDENT_COURSE_STATS']),
    ...mapGetters('users', ['GET_DASHBOARD_STATISTICS']),
    userProfileData() {
      return useJwt.getUserData()
    },
    statistic() {
      return [
        {
          icon: 'UsersIcon',
          color: 'light-primary',
          title: this.GET_DASHBOARD_STATISTICS.all_users,
          subtitle: this.$t('users.total_users'),
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'UserIcon',
          color: 'light-info',
          title: this.GET_DASHBOARD_STATISTICS.staff,
          subtitle: this.$t('users.stuffs'),
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'UserIcon',
          color: 'light-danger',
          title: this.GET_DASHBOARD_STATISTICS.teachers,
          subtitle: this.$t('users.teachers'),
          customClass: 'mb-2 mb-sm-0',
        },
        {
          icon: 'UserIcon',
          color: 'light-success',
          title: this.GET_DASHBOARD_STATISTICS.students,
          subtitle: this.$t('users.students'),
          customClass: '',
        },
      ]
    },
  },
  mounted() {
    this.FETCH_STUDENT_COURSE({ id: this.userProfileData.contact.id, page_size: 1 })
    this.FETCH_CONTACT_COURSE_STATS(this.userProfileData.contact.id)
    this.FETCH_DASHBOARD_STATISTICS()
  },
  methods: {
    ...mapActions('contacts', ['FETCH_STUDENT_COURSE', 'FETCH_CONTACT_COURSE_STATS']),
    ...mapActions('users', ['FETCH_DASHBOARD_STATISTICS']),
  },
}
</script>
