<template>
  <div>
    <!-- <filters
      :add="false"
      :search="true"
      @filtered-items="filter"
      @add-button-clicked="addClass"
    /> -->
    <b-card no-body>
      <b-table
        hover
        :fields="fields"
        :items="GET_TEACHER_GROUPS"
        show-empty
        responsive
        :empty-text="$t('general.no_data_to_view')"
        style-class="slots_text_align vgt-table bordered"
        @row-clicked="rowClick"
      >
        <template #head()="data">
          {{ $t(data.label) }}
        </template>
        <template #cell(actions)="data">
          <div class="text-center">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="btn-icon mr-50 p-25"
              size="sm"
              @click="$router.push(`/class/class-detail/${data.item.group.id}`)"
            >
              <feather-icon size="22" icon="ChevronsRightIcon" />
            </b-button>
          </div>
        </template>
      </b-table>

      <!-- pagination -->
      <!-- slot-scope="props" -->
      <!-- <template>
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">{{ $t('general.show_from') }}</span>
            <b-form-select
              v-model="params.page_size"
              :options="pageOptions"
              class="mx-1"
              @change="changePerPage"
            />
            <span class="text-nowrap"> {{ $t('general.show_to') }} </span>
          </div>
          <div>
            <b-pagination
              v-model="params.page"
              :total-rows="GET_TEACHER_GROUPS.count"
              :per-page="params.page_size"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @change="changePage"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template> -->
    </b-card>
  </div>
</template>

<script>
import {
  BTable,
  // BFormSelect,
  // BPagination,
  BButton,
  BCard,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { mapActions, mapGetters } from "vuex";
// import filters from '@/components/filters.vue'
import areYouSure from "@/mixins/areYouSure";
import toast from "@/mixins/toast";

export default {
  components: {
    BCard,
    BTable,
    // BFormSelect,
    // BPagination,
    BButton,
    // filters,
  },
  directives: {
    Ripple,
  },
  mixins: [areYouSure, toast],
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      pageOptions: [3, 5, 10],
      params: {
        page_size: 10,
        page: 1,
        ordering: null,
        search: null,
      },
      fields: [
        {
          label: "classes.class_title",
          key: "group.title",
        },
        {
          label: "classes.students",
          key: "group.students_count",
        },
        // {
        //   label: 'Дата начала доступа',
        //   key: 'start_time',
        // },
        // {
        //   label: 'Дата окончания доступа',
        //   key: 'end_time',
        // },
        {
          label: "general.actions",
          key: "actions",
          thClass: "text-center",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("contacts", ["GET_TEACHER_GROUPS"]),
  },
  mounted() {
    this.FETCH_TEACHERS_GROUPS(this.userData.contact.id);
  },
  methods: {
    ...mapActions("contacts", ["FETCH_TEACHERS_GROUPS"]),
    addClass() {
      this.$bvModal.show("AddEditClassModal");
    },
    rowClick(data) {
      this.$router.push(`/class/class-detail/${data.group.id}`);
    },
    // for pagination and filters
    changePerPage(page_size) {
      this.params.page_size = page_size;
      this.params.page = 1;
      this.FETCH_TEACHERS_GROUPS(this.params);
    },
    changePage(value) {
      this.params.page = value;
      this.FETCH_TEACHERS_GROUPS(this.params);
    },
    filter(filteredData) {
      this.params = { ...this.params, ...filteredData };
      this.FETCH_TEACHERS_GROUPS(this.params);
    },
  },
};
</script>

<style></style>
