var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"card-company-table"},[_c('b-tabs',[_c('b-tab',{attrs:{"title":_vm.$t('tests.last_activity')}},[_c('b-table',{staticClass:"mb-0",attrs:{"items":_vm.GET_STUDENT_COURSE.filter(function (e) { return e.is_individual && !e.is_outdated; }),"responsive":"","fields":_vm.fields,"show-empty":"","empty-text":_vm.$t('general.no_records_to_show'),"hover":""},on:{"row-clicked":function (e) { return _vm.goToCourse(e); }},scopedSlots:_vm._u([{key:"cell(index)",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"head()",fn:function(ref){
var label = ref.label;
return [_vm._v(" "+_vm._s(_vm.$t(label))+" ")]}},{key:"cell(title)",fn:function(data){return [_c('div',{staticClass:"font-weight-bolder"},[_vm._v(" "+_vm._s(data.item.course.title)+" ")]),(data.item.course.subject[_vm.$i18n.locale])?_c('small',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(data.item.course.subject[_vm.$i18n.locale])+" ")]):_vm._e()]}},{key:"cell(access)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$_dateTimeFormatter(item.access_start_date).split(',')[0])+" - "+_vm._s(_vm.$_dateTimeFormatter(item.access_end_date).split(',')[0])+" ")]}},{key:"cell(group)",fn:function(ref){
var value = ref.value;
return [_c('b-badge',{attrs:{"variant":value ? 'success': 'danger'}},[_vm._v(" "+_vm._s(value ? value: 'Индивидуал')+" ")])]}},{key:"cell(chapter_statistics)",fn:function(ref){
var item = ref.item;
return [(_vm.GET_STUDENT_COURSE_STATS(item.course.id).chapter_statistics || _vm.GET_STUDENT_COURSE_STATS(item.course.id).chapter_statistics === 0)?_c('b-progress',{staticClass:"progress-bar-primary min-width-100",attrs:{"value":_vm.GET_STUDENT_COURSE_STATS(item.course.id).chapter_statistics,"max":"100","striped":"","variant":"primary","animated":"","show-value":""}}):_c('div',{staticClass:"d-flex justify-content-center"},[_c('img',{staticStyle:{"max-width":"100%","width":"50%","height":"50px","object-fit":"contain"},attrs:{"src":require("@/assets/images/icons/loading.gif"),"alt":"loading"}})])]}},{key:"cell(teachers)",fn:function(ref){
var item = ref.item;
return [_c('b-avatar-group',{attrs:{"size":"32px"}},_vm._l((item.teachers),function(teacher){return _c('b-avatar',{key:teacher.id,staticClass:"pull-up",attrs:{"variant":"info","title":teacher.full_name,"src":teacher.photo}})}),1)]}}])})],1),_c('b-tab',{attrs:{"title":_vm.$t('tests.outdated')}},[_c('b-table',{staticClass:"mb-0",attrs:{"items":_vm.GET_STUDENT_COURSE.filter(function (e) { return e.is_outdated; }),"responsive":"","fields":_vm.fields,"show-empty":"","empty-text":_vm.$t('general.no_records_to_show'),"hover":""},on:{"row-clicked":function (e) { return _vm.goToCourse(e); }},scopedSlots:_vm._u([{key:"cell(index)",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"head()",fn:function(ref){
var label = ref.label;
return [_vm._v(" "+_vm._s(_vm.$t(label))+" ")]}},{key:"cell(title)",fn:function(data){return [_c('div',{staticClass:"font-weight-bolder"},[_vm._v(" "+_vm._s(data.item.course.title)+" ")]),(data.item.course.subject[_vm.$i18n.locale])?_c('small',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(data.item.course.subject[_vm.$i18n.locale])+" ")]):_vm._e()]}},{key:"cell(access)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$_dateTimeFormatter(item.access_start_date).split(',')[0])+" - "+_vm._s(_vm.$_dateTimeFormatter(item.access_end_date).split(',')[0])+" ")]}},{key:"cell(group)",fn:function(ref){
var value = ref.value;
return [_c('b-badge',{attrs:{"variant":value ? 'success': 'danger'}},[_vm._v(" "+_vm._s(value ? value: 'Индивидуал')+" ")])]}},{key:"cell(teachers)",fn:function(ref){
var item = ref.item;
return [_c('b-avatar-group',{attrs:{"size":"32px"}},_vm._l((item.teachers),function(teacher){return _c('b-avatar',{key:teacher.id,staticClass:"pull-up",attrs:{"variant":"info","title":teacher.full_name,"src":teacher.photo}})}),1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }