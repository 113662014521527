var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-table',{attrs:{"id":"course-list-of-teacher","striped":"","fields":_vm.fields,"show-empty":"","responsive":"","empty-text":_vm.$t('general.no_data_to_view'),"items":_vm.courses},on:{"row-clicked":function (data) { return _vm.$router.push(("/users/" + (data.get_user) + "/info")); }},scopedSlots:_vm._u([{key:"head()",fn:function(ref){
var label = ref.label;
return [_vm._v(" "+_vm._s(_vm.$t(label))+" ")]}},{key:"cell(phones)",fn:function(ref){
var value = ref.value;
return [_vm._l((value),function(item){return _c('div',{key:item},[_c('b-badge',{staticClass:"mr-1",attrs:{"variant":"primary"}},[_vm._v(" +"+_vm._s(item)+" ")])],1)}),(!value.length)?_c('div',[_vm._v(" - ")]):_vm._e()]}},{key:"cell(gender)",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.$t('users.'+ value))+" ")]}},{key:"cell(full_name)",fn:function(ref){
var item = ref.item;
return [_c('b-avatar',{staticClass:"mr-2",attrs:{"size":"md","src":item.photo}}),_c('span',[_vm._v(_vm._s(item.full_name))])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }